.loginContainer{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 80%;
    justify-content: center;
    align-items: center;
}

.loginForm{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 24px;
}

.loginForm > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 26px;
}

.loginForm input {
    height: 20px;
}


.loginForm > div span{
    margin-bottom: 6px;
}

.loginForm > div input{
    width: 300px;
}

.submit-btn {
    width: 100%;
    font-size: 24px;
    height: 40px;
    line-height: 40px;
}

.loginForm > button:hover{
    background: #fdce50;
}

.loginForm .alert{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}