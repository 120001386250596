.totalNumber {
  background-color: #fdce50;
}
.location {
  border: 1px solid #ccc;
}

.member_img {
  width: 100%;
  /* min-height: 100px; */
  /* border-radius: 50%; */
}
.image-cropper {
  border-radius: 50%;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  /* background: #ccc; */
}
