body {
  font-family: Microsoft JhengHei;
  padding-bottom: 20px;
  scroll-behavior: smooth;
}

div.justify {
    text-align: justify;
    text-justify: inter-ideograph;
    -ms-text-justify: inter-ideograph; /*IE9*/
    -moz-text-align-last:justify; /*Firefox*/
    -webkit-text-align-last:justify; /*Chrome*/

}
div.justify:after {
    content: '';
    display: inline-block;
    width: 100%;
}

.map-size{
	margin: 0;
	padding: 0; 
	border: 0;
  	width:100%;
  	height:400px;
}

.txt-red {
	color:#ff4848;
}

.txt-gray {
	color:#aaa;
}

.border-none {
	margin: 0;
	padding: 0; 
	border: 0;
}

.border-top {
	margin-top: 5em; 
}

.border-bottom {
	margin-bottom: 5em; 
}

.keyboard-bottom {
	margin-bottom: 20em; 
}

.input-hide {
	visibility: hidden;
	opacity: 0;
	height: 0;
	
	-webkit-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
}

.btn-allwd {
	margin: 0.5em 0;
	padding: 0.6em 0;
	background:black;
	border: 0;
	width:100%;	
	height:2.5em;
	color:#fff;
	border-radius: 50em;
	-webkit-transform: translateZ(0);
  	transform: translateZ(0);
	-moz-osx-font-smoothing: grayscale;
	-webkit-transition-duration:  0.1s;
  	transition-duration:  0.1s;	
}

.btn-allwd:hover,.btn-allwd:focus {
	background:#ffc107;
	-webkit-transform: scale(0.95);
	-ms-transform: scale(0.95);
	transform: scale(0.95);
}

.btn-addpic {
	border: 0;
	width:100%;
	max-width: 30em;
	height: auto;
	-webkit-transform: translateZ(0);
  	transform: translateZ(0);
	-moz-osx-font-smoothing: grayscale;
	-webkit-transition-duration:  0.1s;
  	transition-duration:  0.1s;	
}

.btn-addpic:hover,.btn-addpic:focus {
	-webkit-transform: scale(0.95);
	-ms-transform: scale(0.95);
	transform: scale(0.95);
}

.txt-input {
	background-image: none;
}

.txt-input:focus {
  outline: none;
  border:0.05em solid black;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px black;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px black;
}

.btn-gps {
	margin-top: 0.3em; 
	background-image: url('../gui/point_b.png');
    background-color:#fff;
	background-repeat: no-repeat;
	background-position: center;
	width:3.5em;	
	height:3.5em;
    border: 0;
}

.btn-gps:hover,
.btn-gps:focus {
	background-image: url('../gui/point_bu.png');	
}

.btn-danger { 
	margin-top:0.5em;
	background-image: url('../gui/danger.png');
	background-color:#fff;
	background-repeat: no-repeat;
	background-position: center;
	width:30em;	
	height:30em;
    	border: 0;
}

.btn-addEvent { 
	margin-top:0.5em;
	background-image: url('../gui/add.png');
	background-color:#fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 80% 80%;
	width:7em;	
	height:7em;
	border: 0;
}

.btn-addEvent:hover,
.btn-addEvent:focus {
	background-image:url('../gui/add_dn.png');
}

.btn-remove { 
	margin-top:0.5em;
	background-image: url('../gui/remove.png');
	background-color:#fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 80% 80%;
	width:3em;	
	height:3em;
	border: 0;
}

.btn-remove:hover,
.btn-remove:focus {
	background-image:url('../gui/remove_dn.png');
}


.btn-text { 
	margin-top:0.5em;
	background-image: url('../gui/text.png');
	background-color:#fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 80% 80%;
	width:7em;	
	height:7em;
	border: 0;
}

.btn-text:hover,
.btn-text:focus {
	background-image:url('../gui/text_dn.png');
}

.btn-pic { 
	margin-top:0.5em;
	background-image: url('../gui/pic.png');
	background-color:#fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 80% 80%;
	width:7em;	
	height:7em;
	border: 0;
}

.btn-pic:hover,
.btn-pic:focus {
	background-image:url('../gui/pic_dn.png');
}

.btn-mic { 
	margin-top:0.5em;
	background-image: url('../gui/mic.png');
	background-color:#fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 80% 80%;
	width:7em;	
	height:7em;
	border: 0;
}

.btn-mic:hover,
.btn-mic:focus {
	background-image:url('../gui/mic_dn.png');
}

.btn-next { 
	margin-top:0.5em;
	background-image: url('../gui/next.png');
	background-color:#fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 80% 80%;
	width:7em;	
	height:7em;
	border: 0;
}

.btn-next:hover,
.btn-next:focus {
	background-image:url('../gui/next_dn.png');
}

.btn-cam { 
	margin-top:0.5em;
	background-image: url('../gui/cam.png');
	background-color:#fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 80% 80%;
	width:7em;	
	height:7em;
    	border: 0;
}

.btn-cam:hover,
.btn-cam:focus {
	background-image:url('../gui/cam_dn.png');
}

.btn-save { 
	margin-top:0.5em;
	/* background-image: url('../gui/save.png'); */
	background-color:#fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 80% 80%;
	width:5em;	
	height:5em;
    	border: 0;
}

.btn-save:hover,
.btn-save:focus {
	/* background-image:url('../gui/save_dn.png'); */
}

.btn-wifi {
	margin-top: 0.3em; 
	/* background-image: url('../gui/wifi.png'); */
    background-color:#fff;
	background-repeat: no-repeat;
	background-position: center;
	width:3.5em;	
	height:3.5em;
    border: 0;
}

.btn-switch {
	margin-top: 0.3em; 
	/* background-image: url('../gui/switch.png'); */
    background-color:#fff;
	background-repeat: no-repeat;
	background-position: center;
	width:3.5em;	
	height:3.5em;
    border: 0;
}

.img-center {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
	width:100%;
	text-align:-webkit-center;
}

.video {
  border: 0;
  margin: 0;
  padding: 0;
  width:100%;
}

.video-youtube {
	position: relative;
  	border: 0;
  	margin: 0;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
}

.black-back{
	background-color:black;
}

.video-youtube iframe, .video-youtube object, .video-youtube embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.qrcode-img{
	max-width:15em;
	width:100%;
}

.input-search {
	background:#fff; 
	border: 0;
	height:2em;
	border-radius: 5em;
	-webkit-border-radius: 5em;
	-moz-border-radius: 5em;
}

@media (max-width: 768px) {
  .div-search {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}

#mycheck  input[type=checkbox].css-checkbox {
	position: absolute;
	z-index: -1000;
	left: -1000px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}
#mycheck  input[type=checkbox].css-checkbox + label.css-label {
	padding-left: 30px;
	height: 20px;
	display: inline-block;
	line-height: 20px;
	background-repeat: no-repeat;
	background-position: 0 0;
	vertical-align: middle;
	cursor: pointer;
}
#mycheck  input[type=checkbox].css-checkbox:checked + label.css-label {
	background-position: 0 -20px;
}
#mycheck  label.css-label {
    background-image:url('../gui/checkbox.png');
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}